var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import SplashLogger from "utils/SplashLogger";
import * as Sentry from "@sentry/browser";
import { getCookie } from "utils/index";
import { CONSTANTS } from "./constants";
var loggerName = 'CustomTrackingModulesHelper';
export var isParent = function () {
    var _a;
    return ((_a = window.trackerProperties) === null || _a === void 0 ? void 0 : _a.userType) === "parent";
};
export var isTeacher = function () {
    var _a;
    return ((_a = window.trackerProperties) === null || _a === void 0 ? void 0 : _a.userType) === "teacher";
};
export var isStudent = function () {
    var _a;
    return ((_a = window.trackerProperties) === null || _a === void 0 ? void 0 : _a.userType) === "student";
};
export var isGuest = function () {
    var _a;
    return ((_a = window.trackerProperties) === null || _a === void 0 ? void 0 : _a.userType) === "guest";
};
export var checkIsWebView = function () {
    var cookiesList = document.cookie.split(';');
    if (cookiesList.length > 0) {
        var index = cookiesList.findIndex(function (x) { return x.includes('web_view'); });
        return index !== -1;
    }
    return false;
};
export var getAppConfig = function () {
    var appConfig = localStorage.getItem('appDeviceConfig');
    if (appConfig) {
        return JSON.parse(appConfig);
    }
    return null;
};
export var prepareAnalyticsProperties = function (_event, properties, params) {
    var _a, _b;
    var currentUser = params.user;
    properties["sl_source_id"] = 'web-core-ui'; // keeping as it is as for legacy reasons
    properties["sl_source"] = 'web-core-ui';
    properties['WebCoreTrackerModule'] = true;
    properties = attachFrontendSpecficProps(properties);
    var eventTimeMs;
    if (isTimePropValid(properties['time'])) {
        eventTimeMs = properties['time'];
    }
    else {
        eventTimeMs = Date.now();
    }
    var eventServerUtcTimeMs = eventTimeMs - (shouldConsiderSystemTimeDifference(eventTimeMs) ? window.systemTimeDifferenceInMs : 0);
    properties['time'] = eventServerUtcTimeMs;
    properties['systemTimeDifferenceInMs'] = window.systemTimeDifferenceInMs;
    if ((currentUser === null || currentUser === void 0 ? void 0 : currentUser.userEventTrack) && (!params.mixpanelBlockAllTypeListsForWeb || params.mixpanelBlockAllTypeListsForWeb.length === 0)) {
        var userType = currentUser ? (_a = window.trackerProperties) === null || _a === void 0 ? void 0 : _a.userType : "guest";
        window.mixpanel_blocked_events = JSON.stringify(params.mixpanelGetBlockedEventNamesForWeb);
        properties['User Type'] = userType;
        if (currentUser.usageMode) {
            properties['Mode'] = currentUser.usageMode;
        }
        properties['Password Type'] = (_b = window.trackerProperties) === null || _b === void 0 ? void 0 : _b.passwordType;
        var teacherTypeInEventProps = properties['Teacher Type'];
        // add super properties
        if (currentUser && !isGuest()) {
            if (params === null || params === void 0 ? void 0 : params.superProperties) {
                properties = Object.assign({}, properties, params.superProperties);
            }
            else {
                SplashLogger.error(loggerName, 'Super properties not present');
                Sentry.setContext('event', { 'name': _event, 'properties': properties });
                Sentry.captureMessage('Super properties not present', "error");
            }
            if (currentUser && (isParent() || isStudent())) {
                properties['User Persona Type'] = currentUser.b2cPersonaType;
            }
        }
        if (currentUser && currentUser.teacherTypeForMixpanel)
            properties['Teacher Type'] = teacherTypeInEventProps === 'Co-Teacher' ? 'Co-Teacher' : currentUser.teacherTypeForMixpanel;
        if (!properties['Signup Flow']) {
            if (currentUser) {
                properties['Signup Flow'] = currentUser.analyticsFlowProperties["Signup Flow"];
                if (currentUser.analyticsFlowProperties["Signup Sub Flow"]) {
                    properties['Signup Sub Flow'] = currentUser.analyticsFlowProperties["Signup Sub Flow"];
                }
            }
            else if (currentUser.analyticsSignupFlow) {
                properties['Signup Flow'] = currentUser.analyticsSignupFlow;
            }
        }
        if (isParent()) {
            var creationDate = new Date(window.trackerProperties.createdAt).getTime();
            var currentTime = Date.now();
            properties['Days Since Signup'] = Math.round((currentTime - creationDate) / (1000 * 60 * 60 * 24));
        }
        if (isTeacher()) {
            //Setting the segment original/variation for the ongoing teacher referral AB test for all teachers.
            if (!("Teacher Referral Segment" in properties)) {
                properties['Teacher Referral Segment'] = getAbTestInfo(currentUser);
            }
            properties['Paid Status'] = currentUser.teacherState;
            properties['Days Since Class Creation'] = currentUser.daysSinceClassCreation;
            properties['Licence Type'] = currentUser.licenseType;
        }
    }
    return properties;
};
export var isCMPVariantAndAnalyticsConsentTrue = function () {
    var _a;
    // window.CookieConsent Module would only be present in case of variant user so using this for variant check.
    // window.CookieConsent is built in this file inside Web repo (cmp variant user only)=> app/views/layouts/shared/_configure_consent_banner.html.slim 
    if (window.CookieConsent && typeof window.CookieConsent.getContextDto === 'function') {
        var contextDto = window.CookieConsent.getContextDto();
        return !!((_a = contextDto === null || contextDto === void 0 ? void 0 : contextDto.consent_categories) === null || _a === void 0 ? void 0 : _a.analytics);
    }
    return false;
};
export var attachFrontendSpecficProps = function (properties) {
    properties || (properties = {});
    properties["Platform"] = 'web';
    var appConfigInfo = getAppConfig();
    if (checkIsWebView() && appConfigInfo) {
        properties = __assign(__assign({}, properties), appConfigInfo);
        SplashLogger.debug(loggerName, 'these are the added properties', appConfigInfo);
    }
    if (window.sm_addword_utms) {
        setSmAddwordUtms(properties);
    }
    attachMixpanelUtmParamsToProps(properties);
    var deviceType = getCookie('device_type');
    properties['Device Type'] = deviceType;
    properties['user_agent'] = navigator.userAgent;
    return properties;
};
export var getReferrerProps = function () {
    var refProps = {};
    var referrer = document === null || document === void 0 ? void 0 : document.referrer;
    try {
        if (referrer) {
            var referrerURL = new URL(referrer);
            var referrerDomain = referrerURL.hostname.replace('www.', '');
            refProps["$referrer"] = referrer;
            refProps["$referring_domain"] = referrerDomain;
        }
    }
    catch (e) {
        SplashLogger.error(loggerName, e);
    }
    return refProps;
};
var getAbTestInfo = function (currentUser) {
    var info = null;
    if (currentUser && isTeacher()) {
        info = currentUser.referralProgramAbTestFlow;
    }
    return info;
};
var setSmAddwordUtms = function (properties) {
    if (window.sm_addword_campaign) {
        properties['adCampaign'] = window.sm_addword_campaign;
    }
    if (window.sm_addword_group) {
        properties['adGroup'] = window.sm_addword_group;
    }
    if (window.sm_addword_tag) {
        properties['adTag'] = window.sm_addword_tag;
    }
};
export var sleep = function (sec) { return new Promise(function (r) { return setTimeout(r, sec * 1000); }); };
export var trackCallbackMethodToSentry = function (callbackMethod, event, source) {
    try {
        // track events where callback methhod is sent to sentry to analyse
        // if the use case is valid
        // send data to sentry in sampled manner
        var sampleRate = WebCoreUIConfig.sentry.customSampleRate.callbackMethod;
        if (Math.random() < sampleRate) {
            Sentry.captureMessage("Callback method received", {
                level: "warning",
                tags: { "event": event },
                extra: { "fnDef": callbackMethod.toString(), source: source },
            });
        }
    }
    catch (err) {
        console.error(err);
    }
};
export var isTimePropValid = function (time) {
    try {
        if (!time || typeof time != "number") {
            return false;
        }
        new Date(time); // parsing success. return true
        return true;
    }
    catch (e) {
        SplashLogger.error("Invalid time prop sent in event;");
        return false;
    }
};
export var attachBufferDurationToProps = function (props, eventTime, bufferReason) {
    props = props || {};
    props['fe_buffer_reason'] = window.trackerModulesBufferReason || bufferReason;
    var timeNow = new Date();
    var bufferDuration = (timeNow.getTime() - eventTime.getTime()) / 1000;
    props['fe_buffer_duration'] = bufferDuration;
    var eventTimeMs;
    if (isTimePropValid(props['time'])) {
        eventTimeMs = props['time'];
    }
    else {
        eventTimeMs = (eventTime === null || eventTime === void 0 ? void 0 : eventTime.getTime()) || timeNow.getTime();
    }
    var eventServerUtcTimeMs = eventTimeMs - (shouldConsiderSystemTimeDifference(eventTimeMs) ? window.systemTimeDifferenceInMs : 0);
    props['occurred_at_epoch'] = eventServerUtcTimeMs;
    props['systemTimeDifferenceInMs'] = window.systemTimeDifferenceInMs;
    return props;
};
var shouldConsiderSystemTimeDifference = function (timeDifferenceInMilliseconds) {
    // considerable time mismatch. (> 2 seconds)
    return Math.abs(timeDifferenceInMilliseconds) > CONSTANTS.minimumThresholdForSystemMismatchPropMs;
};
var attachMixpanelUtmParamsToProps = function (properties) {
    var _a, _b;
    var utmProperties = (_a = document.querySelector('#mixpanel-utm-properties')) === null || _a === void 0 ? void 0 : _a.dataset.properties;
    if (utmProperties) {
        try {
            Object.assign(properties, JSON.parse(utmProperties));
        }
        catch (e) {
            SplashLogger.error("Error in parsing utmProperties JSON: ".concat(utmProperties));
            Sentry.captureMessage("Error in parsing utmProperties JSON", {
                level: "error",
                tags: {
                    analyticsId: (_b = window.trackerProperties) === null || _b === void 0 ? void 0 : _b.analyticsId
                },
                extra: {
                    utmProperties: utmProperties
                }
            });
        }
    }
};
